<script>
import Navbar from "@/components/navbar";
import Service from "@/components/services";
import Features from "@/components/features";
import Pricing from "@/components/pricing";
import Blog from "@/components/blog";
import Contact from "@/components/contact";
import Footer from "@/components/footer";
import InfoTab from "../components/infotab.vue";
import StatisticCount from "../components/statisticCount.vue";
/**
 * Index-1
 */
export default {
  components: {
    Navbar,
    Service,
    Features,
    Pricing,
    Blog,
    Contact,
    Footer,
    InfoTab,
    StatisticCount,
  },
  data() {
    return {
      isToken: false,
    };
  },
  created() {
    this.isToken = localStorage.getItem("user_info");
  },
};
</script>

<template>
  <div class="main-app">
    <Navbar />
    <div v-scroll-spy>
      <!-- Hero Start -->
      <section
        class="hero-1-bg"
        :style="{
          'background-image':
            'url(' + require('@/assets/images/back-img4.jpg') + ')',
        }"
        id="home"
      >
        <!-- <marquee style="color:red" behavior="" direction=""
          >{{ $t("Websiteintest") }}
        </marquee> -->
        <div class="container">
          <div class="row align-items-center main-page">
            <div class="col-lg-6 col-md-12">
              <h1
                class="font-weight-bold mb-4"
                style="color: white;"
                >
                <p v-html="$t('sportonline')"></p>
              </h1>
            </div>
            <div class="col-lg-6 col-md-12">
              <p class="main-text">{{ $t("sportonlineinfo") }}</p>
            </div>
          </div>
        </div>
        <Footer />
      </section>

      <!-- Hero End -->
      <!-- <Service />
      <Features />
      <Pricing />
      <Blog />
      <Contact /> -->
      <!-- <info-tab /> -->
      <!-- <statistic-count /> -->
    </div>
  </div>
</template>
